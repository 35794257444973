import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react'
import * as Icon from "react-feather";

function PortfolioRowCard(props) {
    const [toggler, setToggler] = useState(false);
    const { title, subtitle, imageUrl, largeImageUrl, url } = props.content;
    const handleToggler = (value) => {
        setToggler(value);
    }


    return (
        <div className="mi-contact-infoblock">
            <span className="mi-contact-infoblock-icon">
                <img src={imageUrl} alt={title} />
            </span>
            <div className="mi-contact-infoblock-content">
                <h6>{title}</h6>
                <p>{subtitle.substring(0, 50)}</p>
            </div>

            <div className='row_view_card_container'>

                {!largeImageUrl ? null :
                    <button onClick={() => handleToggler(!toggler)}>
                        <Icon.ZoomIn />
                    </button>
                }
                {url ?
                    <a rel="noopener noreferrer" target="_blank" href={url}>
                        <Icon.Link />
                    </a>
                    : null}

            </div>
            {!largeImageUrl ? null : <FsLightbox
        toggler={toggler}
        sources={largeImageUrl}
        />
      }
        </div>
    )
}

export default PortfolioRowCard