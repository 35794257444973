import React from 'react'
import { converStringToArray } from '../helpers/string.helpers';
import SkillTag from '../components/SkillTag';

export default function Article({article}) {

    var articleImage = {
        backgroundImage : `url(${article.cover_image})`
    }
    
    const articleTags = converStringToArray(article.tags);

    return (
        <li className="ArticleCard_item">
            <div className="ArticleCard">
                <div style={articleImage} className="ArticleImageContainer">

                </div>
                <div className="ArticleCardContent">
                    <h6 className="ArticleCardContent-title">{article.title}</h6>
                    <p className ="ArticleCardContent-description cut-text">{article.description}</p>
                    <div className="ArticleCardContent-tags">
                        {articleTags ? 
                            articleTags.map((tag)=> (
                                <SkillTag tag={tag}/>
                            ))
                        : ""}
                    </div>
                    <a href ={article.url} target="_blank">
                        <button className="ArticleCardContent-button">Read More</button>
                    </a>

                
                </div>
                
            </div>
        </li>
    )
}
