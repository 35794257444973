import React, { useEffect,useState } from 'react'
import Layout from '../components/Layout'
import Sectiontitle from '../components/Sectiontitle'
import axios from 'axios';
import Article from '../components/Article';

export default Articles => {
    //***states***
    const [devtoarticles,setDevtoarticles] = useState([]);

    //***hooks***
    //fetch the data from api
    useEffect(()=> {
        const getDatafromDevto = (async()=>{
            try {
                const res = await axios.get("https://dev.to/api/articles?username=scuz12")
                setDevtoarticles(res.data)
            } catch (err) {
                console.log(err);
            }
        });
        getDatafromDevto();
    },[])

    return (
        <div>
            <Layout>
            <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="My Articles ✍️" shadowText={false} />  
                    <ul className="articles-cards">
                        {devtoarticles.map((article)=>(
                            <Article key ={article.id} article = {article}/>
                        ))}
                    </ul>
                </div>
            </div>
            </Layout>
        </div>
    )
}
