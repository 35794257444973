import React, { useState, useEffect } from "react";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import PortfoliosView from "../components/PortfoliosView";
import LineIcon from 'react-lineicons';
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import PortfoliosRowView from "../components/PortfoliosRowView";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: "white"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function Portfolios() {
  const classes = useStyles();
  const [portfolios, setPortfoios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfoliosPerPage] = useState(9);
  const [viewMode, setViewMode] = useState("grid")



  useEffect(() => {
    let mounted = true;
    axios.get("/api/portfolios").then((response) => {
      if (mounted) {
        setPortfoios(response.data);
      }
    });
    return () => mounted = false;
  }, [portfolios]);

  const indexOfLastPortfolios = currentPage * portfoliosPerPage;
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
  const currentPortfolios = portfolios.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };


  const handleViewModeChange = (e) => {

    setViewMode(e.target.value)
  }

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <div className="flex-container">
            <Sectiontitle title="Portfolios" />
            <div>

              <FormControl className={classes.formControl} variant="filled">
                <InputLabel htmlFor="age-native-simple">View</InputLabel>
                <Select
                  value={viewMode}
                  label="View"
                  onChange={handleViewModeChange}
                >
                  <MenuItem value="grid">
                  <LineIcon name="grid"/> Grid
                  </MenuItem>
                  <MenuItem value="row">
                  <LineIcon name="list"/> Row 
                  </MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>


          {
            viewMode === "grid" ? (
              <PortfoliosView portfolios={currentPortfolios} />
            ) : (
              <PortfoliosRowView portfolios={currentPortfolios} />
            )
          }


          {!(portfolios.length > portfoliosPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={portfoliosPerPage}
              totalItems={portfolios.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Portfolios;
