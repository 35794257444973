import Mock from "../mock";

const portfolioImagePath = "/images/portfolio/";

const database = {
  information: {
    name: "George Hadjisavvas",
    aboutContent:
      "I am a software developer with a burning desire to create innovative solutions and bring ideas to life through code. My passion drives me to constantly learn and improve, and I pour my heart into every project I take on.Let's turn ideas into reality and change the world through technology.",
    age: 29,
    phone: "",
    aboutPageWhoIm:
      "Innovative & Analytical tech mind with 6 years of experience as a web developer. Capable of working with a variety of technologies ,software solutions and managing databases. Valuable team member who has experience in solving problems and developing innovative ideas and possible solutions",
    nationality: "Cypriot",
    language: "English, Greek",
    email: "georgehadjisavvas12@gmail.com",
    socialLinks: {
      facebook: "https://facebook.com/GeorgiosHadjisavvas",
      twitter: "https://twitter.com/sCuz123",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/george-hadjisavvas-41ba40130/",
      dribbble: "",
      github: "https://github.com/sCuz12",
      newsletter : "https://devgnosis.substack.com"
    },
    brandImage: "/images/profile/myLogo.png",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/George-Hadjisavvas-Cv.pdf",
  },
  //Services Section
  services: [
    {
      title: "Back End",
      icon: "lni lni-database",
      details:
        "Creating back end services using Laravel,Node.js and Express.js. Persisting Data into databases such us MySql ,MongoDB.Knowledge of Digital Ocean Cloude Hosting and Netlify",
    },
    {
      title: "Front End",
      icon: "code",
      details:
        "Designing User Interfaces (UI) using tools like Figma. Development of websites using HTML, CSS, Vanilla Javascript and JS framework like React",
    },
    {
      title: "Mobile Application",
      icon: "mobile",
      details:
        "Having experience in mobile development , Developing several IOS applications with Swift & SwiftUI",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "PHP",
      value: 85,
    },
    {
      title: "Laravel",
      value: 85,
    },
    {
      title: "ReactJS/Next.js",
      value: 77,
    },
    {
      title: "Golang",
      value: 65,
    },
    {
      title: "Java",
      value: 65,
    },
    {
      title: "HTML5",
      value: 80,
    },
    {
      title: "CSS3",
      value: 70,
    }, 
    {
      title: "Tailwind",
      value: 80,
    },
    {
      title: "Node.js",
      value: "65",
    },
    {
       title: "Typescript",
      value: "70",
    },
    {
      title: "Swift",
      value: "65",
    },
    {
      title: "Elasticsearch",
      value: "60",
    },
    {
      title: "Docker",
      value: "67",
    },
    {
      title: "Redis",
      value: "70",
    }
  ],
  //Portfolio Section
  portfolios: [
    {
      id: 0,
      title: "Bell3 - Freelance Project",
      subtitle:
        "Bell3 is a mobile application aimed at providing a platform for users - Creators to discover and engage with various types of content, including news articles, images, videos, and more.",
      imageUrl: portfolioImagePath + "/bell3/bell3-thumb.png",
      url: "/images/portfolio/bell3/bell3-app-presentation.pdf",
      largeImageUrl: [
        portfolioImagePath + "/bell3/bell3-login.png",
        portfolioImagePath + "/bell3/bell3-news-1.png",
        portfolioImagePath + "/bell3/bell3-news-2.png",
        portfolioImagePath + "/bell3/bell3-notification.png",
        portfolioImagePath + "/bell3/bell3-create.png",
    
        
      ],
      priority : 10
    },
    {
      id: 0,
      title: "ListyBee (Lists Managment & Collaboration app",
      subtitle:
        "The ultimate Lists/Notes app for effortless & seamless list creation, organization, collaboration",
      imageUrl: portfolioImagePath + "listybee/Listybee-thumb.webp",
      url: "http://www.listybee.com",
      largeImageUrl: [
        portfolioImagePath + "listybee/Listybee-thumb.webp",
        portfolioImagePath + "listybee/scrapeDataMockup.webp",
        portfolioImagePath + "listybee/darkmode.webp",
        portfolioImagePath + "listybee/filterlistsShowcase.webp",
        
      ],
      priority : 20
    },
    {
      id: 1,
      title: "Sitebuildex (Drag & Drop site builder)",
      subtitle:
        "A Platform users can create landing pages easily by drag and drop ",
      imageUrl: portfolioImagePath + "sitebuildex/sitebuildex-thumbnail.png",
      //url: "http://www.sitebuildex.com",
      largeImageUrl: [
        portfolioImagePath + "sitebuildex/sitebuildex-thumbnail.png",
        portfolioImagePath + "sitebuildex/builder.png",
        portfolioImagePath + "sitebuildex/builder2.png",
        portfolioImagePath + "sitebuildex/builder3.png",
        portfolioImagePath + "sitebuildex/builder4.png",
        portfolioImagePath + "sitebuildex/builder5.png",
      ],
      priority : 70,
    },
    {
      id: 2,
      title: "Dog Adopt platform (Goodboi)",
      subtitle:
        "A Platform where shelters can registers and list their dogs and enable users to guide through the several listings of severals shelters with ease !",
      imageUrl: portfolioImagePath + "goodboi/index.png",
      largeImageUrl: [
        portfolioImagePath + "goodboi/listings2.png",
        portfolioImagePath + "goodboi/listings3.png",
        portfolioImagePath + "goodboi/lost_dogs_listings.png",
        portfolioImagePath + "goodboi/shelter_dashboard.png",
        portfolioImagePath + "goodboi/shelter_mylistings.png",
        portfolioImagePath + "goodboi/single_listing.png",
      ],
      priority : 60,
    },
    {
      id: 3,
      title: "STEM Job Portal",
      subtitle:
        "Connecting Job Seekers and Companies: A Laravel-Powered Job Portal with Quick Application",
      imageUrl: portfolioImagePath + "stemjobportal/stemjobportal-thumb.png",
      largeImageUrl: [
        portfolioImagePath + "stemjobportal/stemjobportal1.png",
        portfolioImagePath + "stemjobportal/stemjobportal2.png",
        portfolioImagePath + "stemjobportal/stemjobportal3.png",
        portfolioImagePath + "stemjobportal/stemjobportal4.png",
      ],
      priority : 50,
    },
  {
      id: 9,
      title: "Vscode Extension - GTP3 Code Enlightment",
      url: "https://marketplace.visualstudio.com/items?itemName=GeorgeHadjisavva.GTP3-Code-Enlightment",
      subtitle:
        "A vscode extension tool that use AI GTP3 and help the developer to refactor code , understand code(Code explain) and more ",
      imageUrl: portfolioImagePath + "gtp3-enlightment/gtp3-enlightment-thumb.png",
      largeImageUrl: [
        portfolioImagePath + "gtp3-enlightment/gtp3-enlightment-thumb.png",
        portfolioImagePath + "gtp3-enlightment/gtp3-enlightment-refactor.gif",
        portfolioImagePath + "gtp3-enlightment/gtp3-enlightenment-document.gif",
      ],
      priority : 40,
    },


    {
      id: 4,
      title: "Social Media platform",
      subtitle:
        "Social media platform written in PHP as a backend and JQuery ,HTML,CSS3 as front end with several functionalities such us Likes , add photo, comment,chatting etc",
      imageUrl: portfolioImagePath + "fblikeplatform/socialmedia-thumb.png",
      largeImageUrl: [
        portfolioImagePath + "fblikeplatform/socialmedia-thumb.png",
        portfolioImagePath + "fblikeplatform/socialmedia1.png",
      ],
      priority : 100,
    },

      {
        id: 6,
        title: "Multiplayer Rock paper scissor Game ",
        subtitle:
          "A multiplayer rock paper scissor game builted in (React/Node.js/Socket.io) where you can invite your friend and challenge him",
        imageUrl: portfolioImagePath + "rock-paper-scissors/ingame-mockup.png",
        largeImageUrl: [
          portfolioImagePath + "rock-paper-scissors/ingame-mockup.png",
          portfolioImagePath + "rock-paper-scissors/username.png",
          portfolioImagePath + "rock-paper-scissors/ingame.png",
        ],
        priority : 90,
      },
        {
      id: 5,
      title: "Invlolve in the creation of Startup back-end infastructure",
      subtitle:
        "Invlolve in creating the backend (Authentication , Inventory Sychronization ) . Tools used :  ",
      imageUrl: portfolioImagePath + "adoorcy/adoor-thumb.png",
      largeImageUrl: [
        portfolioImagePath + "adoorcy/adoor-thumb.png",
        portfolioImagePath + "adoorcy/adoor-1.png",
        portfolioImagePath + "adoorcy/adoor-2.png",
      ],
      priority : 60,
    },
    {
      id: 7,
      title: "Eargasm Music App Streaming",
      subtitle:
        "Music app platform to let djs upload their unoficial mixes.Created with PHP , HTML,CSS ,JQUERY",
      imageUrl: portfolioImagePath + "eargasm/eargasm-thumb.png",
      largeImageUrl: [
        portfolioImagePath + "eargasm/eargasmIndex.png",
        portfolioImagePath + "eargasm/eargasmAlbum.png",
      ],
      priority : 70,
    },

    {
      id: 8,
      title: "The funny paws E-shop",
      subtitle: "Eshop For dog clothing",
      imageUrl: portfolioImagePath + "funnypaws/funnypaws-thumb.png",
      largeImageUrl: [portfolioImagePath + "funnypaws/funnypaws-eshop.png"],
      priority : 110,
    },
    {
      id: 10,
      title: "Laravel Package - Elevenlabs Api Wrapper Client",
      subtitle: "Laravel Package - Open source",
      url : "https://packagist.org/packages/georgehadjisavva/elevenlabs-api-client",
      imageUrl: portfolioImagePath + "elevenlabs-client/elevenlabs-package-thumb.png",
      largeImageUrl: [portfolioImagePath + "elevenlabs-client/elevenlabs-package-thumb.png"],
      priority : 20,
    },
    {
      id: 11,
      title: "Govalidator Package",
      subtitle: "Enhance Data Validation with GoValidator: Your Go-to Solution for Input Validation in Go Applications",
      url : "https://github.com/sCuz12/goinputvalidator",
      imageUrl: portfolioImagePath + "golang-struct-validation/golang-struct-validation-thumb.webp",
      largeImageUrl: [portfolioImagePath + "golang-struct-validation/golang-struct-validation-thumb.webp"],
      priority : 30,
    }
  ],
  //Experience
  experience: {
    workingExperience: [
      {
        id: 2,
        year: "July 2023 - Present",
        position: "Full Stack Developer",
        company: "Freelancer",
        displayDetailsBullet: true,
        details: [
          "Designed and maintained RESTful APIs for various applications.",
          "Integrated third-party services and managed database interactions.",
          "Ensured security and compliance standards in API development.",
          "Developed dynamic and responsive user interfaces using React for web and React Native for mobile applications",
          "Integrated state management and optimized performance across platforms for a seamless user experience.",
          "Developed high-performance services , optimizing for speed and efficiency",
          "Implemented concurrent processes to handle large-scale data processing."
        ],
        skillsGained: [
          "Laravel Api",
          "Golang",
          "React-native ",
          "React",
          "HTML5",
          "Docker",
          "Algolia",
          "Redis",
          "MySQL",
        ],
        toolsGained: [
          "Phpstorm",
          "Algolia ",
          "NGINX",
          "Postman",
          "Git",
        ],
      },
      {
        id: 2,
        year: "July 2020 - Present",
        position: "Software developer",
        company: "MindGeek",
        displayDetailsBullet: true,
        details: [
          "Working with Product/Marketing department to identify,recommend and implement improvement for the platform",
          "Developing new features & Microservices in agile environment on a high load platform",
          "Contributing PR reviews to ensure the quality of code",
          "Developing Microservices written in Laravel to support new features of product",
        ],
        skillsGained: [
          "PHP",
          "Laravel",
          "Zend Framework",
          "HTML5",
          "Docker",
          "Elasticsearch",
          "Redis",
          "MySQL",
        ],
        toolsGained: [
          "Jira",
          "Agile",
          "Linux",
          "NGINX",
          "Postman",
          "Git",
          "Docker",
        ],
      },

      {
        id: 3,
        year: "2019 - July 2020",
        position: "Software developer",
        company: "Cablenet",
        displayDetailsBullet: true,
        details: [
          "Contributed in the development of web application though out all the software lifecycle , from requirements to successful deployment ",
          "Contributed in the development of new Microservices (REST/SOAP) API's supporting the business requirements",
          "Contributed in the development of several internal business Processes through BPM Software(Bizagi)",
          "Contributed in the development of fiber optics automation provisioning System working with Springboot",
          "Contributing to the migration of Oracle Billing system working with 3rd party Companies(Intrasoft,Oracle",
        ],
        skillsGained: [
          "Symfony",
          "Java",
          "MySQL",
          "HTML5",
          "Springboot",
          "Camel",
          "Jquery",
        ],
        toolsGained: ["Jira", "Readyapi", "Git", "Asterix"],
      },
      {
        id: 4,
        year: "2016 - 2017",
        position: "Part time Database Adminstrator",
        company: "Fileminders ltd",
        details: [
          "Migrating and analyzing of data from customers existing software to the software of fileminders (M-files)",
          "Executing Daily several queries from Sql Microsoft Server and exporting data",
          "Pre-processing the data using formulas on excel",
        ],
        skillsGained: ["SQL", "Excel", "M-files"],
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2015 - 2019",
        graduation: "Information Systems (Focus  on web Technologies)",
        university: "European University Cyprus  B.Sc",
        details:
          "Receiving Dean List Second Honors, by excelling in classes such us Algorithms&Data structures , Distributed Systems,Advance Java Programming,Advance Web development.",
      },
      {
        id: 2,
        year: "2010 - 2013",
        graduation: "Higher School Graduation",
        university: "Pancyprian Gymnasium - High School",
      },
    ],
  },
  //Blogs section
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],

  //Contact section
  contactInfo: {
    phoneNumbers: ["+357-99931226"],
    emailAddress: ["georgehadjisavvas12@gmail.com"],
  },

  //Mybooks recommendations
  booksRecommendation: [
    {
      id: 1,
      title: "Psycho-Cybernetics",
      description:
        "Cybernetics (loosely translated from the Greek): “a helmsman who steers his ship to port.” Psycho-Cybernetics is a term coined by Dr. Maxwell Maltz, which means, “steering your mind to a productive, useful goal so you can reach the greatest port in the world, peace of mind.”",
      author: "Maxwell Maltz",
      publishDate: "2015",
      category: "self-help",
      image: "/images/books/psycho-cybernetics.jpg",
      link: "https://www.goodreads.com/book/show/155981.Psycho_Cybernetics_A_New_Way_to_Get_More_Living_Out_of_Life?from_search=true&from_srp=true&qid=VhwJyuvAsi&rank=1",
      rating: 9,
    },
    {
      id: 2,
      title: "The Power of now",
      description:
        "It's no wonder that The Power of Now has sold over 2 million copies worldwide and has been translated into over 30 foreign languages. Much more than simple principles and platitudes, the book takes readers on an inspiring spiritual journey to find their true and deepest self and reach the ultimate in personal growth and spirituality: the discovery of truth and light.",
      author: "Eckhart-Tolle",
      publishDate: "2004",
      category: "self-help",
      link: "https://www.goodreads.com/book/show/6708.The_Power_of_Now?ac=1&from_search=true&qid=JuREyrp3rw&rank=1",
      image: "/images/books/powerof-now.jpg",
      rating: 8,
    },

    {
      id: 3,
      title: "Atomic Habits",
      description:
        "No matter your goals, Atomic Habits offers a proven framework for improving--every day. James Clear, one of the world's leading experts on habit formation, reveals practical strategies that will teach you exactly how to form good habits, break bad ones, and master the tiny behaviors that lead to remarkable results.”",
      author: "James Clear",
      publishDate: "2018",
      category: "self-help",
      link: "https://www.goodreads.com/book/show/40121378-atomic-habits?from_search=true&from_srp=true&qid=IsVz9Omop6&rank=1",
      image: "/images/books/atomic-habits.jpg",
      rating: 8,
    },
    {
      id: 4,
      title: "Zero to One",
      description:
        "The great secret of our time is that there are still uncharted frontiers to explore and new inventions to create. In Zero to One, legendary entrepreneur and investor Peter Thiel shows how we can find singular ways to create those new things.",
      author: "Blake Masters",
      publishDate: "2014",
      category: "Business",
      image: "/images/books/zero-to-one.jpg",
      link: "https://www.goodreads.com/book/show/18050143-zero-to-one?ac=1&from_search=true&qid=MiyRTX0Vya&rank=1&error_code=4201&error_message=User%20canceled%20the%20Dialog%20flow#_=_",
      rating: 7,
    },
    {
      id: 4,
      title: "Dune",
      description: "",
      author: "Frank Herbert",
      publishDate: "1965",
      category: "Sci-Fi",
      link: "https://www.goodreads.com/book/show/44767458-dune?ac=1&from_search=true&qid=cYAN0bLa5w&rank=1",
      image: "/images/books/dune.jpg",
      rating: 9,
    },
  ],
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  response.sort((a,b)=>(a.priority > b.priority) ? 1 : -1)
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});

Mock.onGet("/api/books").reply((config) => {
  const response = database.booksRecommendation;
  return [200, response];
});
