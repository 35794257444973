import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";
import Modal from "react-modal"
import papyrusImage from "../assets/papyrus.png"
import {Tooltip} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
// This line is needed to set the app element for screen readers
Modal.setAppElement('#root');

function GreekQuotes() {
    const [quotes,setQuotes] = useState([]);
    const [showQuote , setShowQuote] = useState(false)
    const [showHighLight,setShowHightlight] = useState(true)
    const [showTooltip , setShowTooltip] = useState(false)
    const [isLoading ,setIsLoading] = useState(false)

    useEffect(() => {
        setShowTooltip(showHighLight)
        console.log(showHighLight)
    },[showHighLight])

    const fetchQuote = ()=> {
        const apiURL = process.env.REACT_APP_BACKEND_QUOTES_URL
        console.log(apiURL)
        setIsLoading(true)
        fetch(apiURL)
            .then(response=> response.json())
            .then(data => {
                setIsLoading(false)
                setShowHightlight(false)
                setQuotes(data.quotes)
                setShowQuote(true)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err)
            })

    }

    const closeModal = () => {
        setShowQuote(false)
        setShowHightlight(false)
    }


    return (
        <div>
            <Layout>
                {showHighLight && <div className="dimmed-background"></div>}
                <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
                    <div className="container">
                        <Sectiontitle title="Greek Wisdom Generator 🏛" shadowText={false} />
                        <Smalltitle title ="Discover timeless wisdom with a click" />
                    </div>
                </div>
                <div className={"mi-quotes"}>
                    <Tooltip
                        className={"h-25 w-25"}
                        title="Tap into Ancient Greek Wisdom"
                        arrow
                        open={showTooltip}>
                        <button
                            onClick={fetchQuote}
                            className={`mi-button center ${showHighLight ? 'highlight-button' : ''}`}>
                            {isLoading ? <CircularProgress color='black' /> : 'Generate Quote 🔱'}
                        </button>
                    </Tooltip>
                </div>
                { showQuote && (
                    /**Display modal **/
                    <Modal
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        isOpen = {showQuote}
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.85)', // Adjust the transparency as needed
                                zIndex: 1000 // Ensure it's above other content
                            },
                            content: {
                                zIndex: 999,
                                display:'flex',
                                flexDirection : 'column',
                                alignItems : 'center',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                background: `url(${papyrusImage}) no-repeat center center`, // Set the background image here
                                backgroundSize: 'cover', // Ensure it covers the entire content area
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                padding: '20px',
                                width: '80%', // Adjust width as a percentage of the viewport width
                                height: '100%', // Adjust height as needed, or use 'auto' to scale based on content
                                maxWidth: '600px', // Maximum width of the modal
                                maxHeight: '90%', // Maximum height of the modal
                                border:0
                            }
                        }}
                    >

                        <div className="quotes-container">
                            <h2 className="text-color">Quote</h2>
                            {quotes.map((quote) => (
                                <div className="quotes-text-container">
                                    <p className="text-color-greek font-weight-bold greek">
                                        🏛️{quote.Greek_quote}
                                    </p>

                                    <hr className="divider" />

                                    <div className="pt-5">
                                        <p>Translation</p>
                                        <p className="text-color">
                                            {quote.English_translation}
                                        </p>
                                    </div>

                                    <div className="pt-lg-1">
                                        <p>Description</p>
                                        <p className="text-color">
                                            {quote.Description}
                                        </p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </Modal>
                )}
            </Layout>
        </div>
    );
}

export default GreekQuotes;