import React from "react";
import TrackVisibility from "react-on-screen";
import PortfolioRowCard from "./PortfolioRowCard";

function PortfoliosRowView({portfolios}) {

    return (
        <div className="row mt-30-reverse">
          {portfolios && portfolios.map(portfolio => (
            <TrackVisibility
              once
              offset={200}
              className="col-lg-6 col-md-6 col-12 mt-30"
              key={portfolio.id}
            >
                <PortfolioRowCard content = {portfolio}/>
            </TrackVisibility>
          ))}
        </div>
      );
      
}

export default PortfoliosRowView
