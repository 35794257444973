import React from "react";
import Blog from './Blog';
import Book from './Book';

function BooksView({books}) {
    return (
        <div>
          <div className="bookCover">
            {books.map(book => (
              <Book key = {book.id}
              id={book.id} 
              title={book.title} 
              description={book.description}
              image = {book.image}
              category = {book.category}
              publishDate = {book.publishDate}
              rating = {book.rating}
              link = {book.link}
              />
            ))}
          </div>
        </div>
      );
}

export default BooksView