import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import BooksView from '../components/BooksView'


function Books(){
    let shadowText = false;
    const [books,setBooks] = useState([]);

    useEffect(() => {
        axios.get('/api/books')
          .then(response =>{
              setBooks(response.data);
          })
      }, [books])




    return (
        <Layout>

        <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
            <div className="container">
                <Sectiontitle title="Books Recommendations📚" shadowText={false} />                
                <div>
                    <BooksView books={books}/>
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default Books