import React from "react";

//Checks wether shadoowText will show and adds the shadow SPAN
function Sectiontitle(props) {
  const showShadowMirror = () => {
    var show = (typeof props.shadowText === 'undefined') ? true :  props.shadowText;
    let mirror;
    if (show) {
      return <span>{props.title}</span>
    } else {
      return <span></span>
    }
  }
  

  return (
    <div className="mi-sectiontitle">
      <h2>{props.title}</h2>
      {showShadowMirror()}
    </div>
  );
}

export default Sectiontitle;
