import React from "react";
import {Badge} from '@material-ui/core'


function Book (props) {
    return <div className="media">
        <Badge badgeContent = {props.rating} color ={props.rating>6?'primary' : 'secondary'} />

        <a href ={props.link} target="_blank">
            <img 
                className="poster" 
                src={props.image}>
            </img>
        </a>
        <b className="title">{props.title}</b>
        <span class className="title">{props.category}</span>    
    </div>
}

export default Book