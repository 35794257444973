import React from 'react'

const SkillTag = ({tag}) => {
    return (
            <span className="skillTag">
                {tag}
            </span>
    )
}

export default SkillTag
