import React from "react";
import { isArray } from "util";

function Resume(props) {
  const {id,year, position, graduation, university, company, details,displayDetailsBullet,skillsGained,toolsGained} = props.resumeData;
  let renderDetails;
  let renderSkillsGained;
  let renderToolsGained;

  renderDetails = <p>{details}</p>
  //Display in bullets the details 
  if(displayDetailsBullet && isArray(details)) {
    renderDetails = details.map((bulletPoint)=>
    <li key={Math.random()}>{bulletPoint}</li>
    );
  }

  //display Skills gained
  if (skillsGained !== undefined && skillsGained.length !== 0) {
    renderSkillsGained = <p><i>{skillsGained.join(',')}</i></p>
  }

  //Display Tools Gained
  if(toolsGained !== undefined && toolsGained.length !== 0) {
    renderToolsGained = <p><i>{toolsGained.join(',')}</i></p>
  }

  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation}</h5>
        <h6 className="mi-resume-company">{company || university}</h6>
        {renderDetails}
        <div className = "mi-resume-skillsGained">
          {renderSkillsGained}
        </div>
        <div className = "mi-resume-toolsGained">
          {renderToolsGained}
        </div>
      </div>
    </div>
  );
};

export default Resume;
