import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Resumes from "./pages/Resumes";
import Portfolios from "./pages/Portfolios";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import BlogDetails from "./pages/BlogDetails";
import Notfound from "./pages/Notfound";
import Analytics from 'react-router-ga';
import Books from './pages/Books';
import Articles from './pages/Articles';
import GreekQuotes from "./pages/GreekQuotes";

const trackingId = "UA-115454723-1"; // Replace with your Google Analytics tracking ID

function App() {

  return (
    <BrowserRouter>
      <Analytics id={trackingId} debug>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/resume" component={Resumes} />
          <Route path="/portfolios" component={Portfolios} />
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />
          <Route path="/contact" component={Contact} />
          <Route path="/books" component={Books} />
          <Route path="/articles" component={Articles} />
          <Route path="/greekQuotes" component={GreekQuotes} />
          <Route path="*" component={Notfound} />
        </Switch>
      </Analytics>
    </BrowserRouter>
  );
}

export default App;
